<template>
  <layout-carrier>
    <router-view />
  </layout-carrier>
</template>

<script>
import LayoutCarrier from '@core/layouts/layout-carrier/LayoutCarrier.vue'

export default {
  components: {
    LayoutCarrier
  }
}
</script>
