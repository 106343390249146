<template>
  <b-dropdown
    right
    toggle-class="dropdown-user-link"
    class="dropdown-user"
    variant="link"
  >
    <b-dropdown-text>
      <p class="text-right m-0 pr-05 font-weight-bolder"> {{ myData.fullName || myData.username || `${myData.first_name} ${myData.last_name}` }}</p>
      <p class="text-right m-0 pr-05"> {{ $options.filters.title(myData.role) }} </p>
    </b-dropdown-text>
    <b-dropdown-divider />
    <template #button-content>
      <b-avatar
        size="40"
        :src="myData.avatar"
        variant="primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!myData.fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{$t('Cerrar sesión')}}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import { BDropdownItem, BAvatar, BDropdownText } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BDropdownItem,
    BAvatar,
    BDropdownText
  },
  data() {
    return {
      myData: this.$session.get('cas_user') || {},
      avatarText
    }
  },
  watch: {
    userData: {
      handler () {
        this.myData = this.userData
      },
      deep: true
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    ...mapGetters({
      userData: 'getUserData'
    })
  },
  methods: {
    logout() {
      this.$root.$data.auth.signOut()
        .then(resp => {
          Cookies.remove('session_id')
        })
      this.$store.dispatch('fetchLogout', this)
    }
  }
}
</script>