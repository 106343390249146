<template>
  <div :class="contentWidth === 'boxed' ? 'container p-0' : null">
    <app-breadcrumb v-if="$route.meta.breadcrumb" :disableHome="true"/>   
    <b-navbar class="simplify--navbar" :sticky="true">
      <img src="https://storage.googleapis.com/tickets-misc/enviame-white-small.png" alt="Logo_enviame" class="float-left simplify--navbar-logo">
      <b-button href="/shipments-carriers" class="simplify--navbar-button" :class="$route.fullPath === '/shipments-carriers' ? 'simplify--navbar-active' : ''" variant="link">Envíos</b-button>
      <b-button href="/request-carriers" class="simplify--navbar-button" :class="$route.fullPath === '/request-carriers' ? 'simplify--navbar-active' : ''" variant="link">Instrucciones</b-button>
      <user-dropdown class="simplify--navbar-end"/>
    </b-navbar>
    <router-view />
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import UserDropdown from './components/userDropdown.vue'

export default {
  components: {
    AppBreadcrumb,
    BNavbar,
    UserDropdown
  },
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  }
}
</script>
<style lang="scss">
  .navbar.simplify--navbar {
    background: linear-gradient(90deg, #2054A3 0%, #0086C8 100%);
    display: grid;
    grid-template-columns: repeat(3, 1fr) 4fr;
    height: 126px;

    .simplify--navbar-logo {
      margin-left: 3%;
      margin-right: 3%;
    }

    .simplify--navbar-button {
      color: white;
    }

    .simplify--navbar-active {
      text-decoration: underline;
    }
    
    .simplify--navbar-end {
      justify-self: end;
    }
  }

</style>
